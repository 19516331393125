import { useState, useEffect } from "react";
import { API, graphqlOperation } from "aws-amplify";

import Role from "./Role";
import Skills from "./Skills";
import { getUser, listJobTypes } from "graphql/queries";
import Languages from "./Languages";
import getCurrentClass from "../shared/utils";
import ConnectAccounts from "./ConnectAccounts";

const StackAndSkills = ({
  user,
  handleSubStepQueryParams,
  currentSubStep,
  setDisabled,
  saveAttributes,
  refs,
  setAutoSaved,
  dispatchUserUpdated,
}) => {
  const [userSkills, setUserSkills] = useState([]);
  const [userLanguages, setUserLanguages] = useState([]);
  const [jobRoles, setJobRoles] = useState([]);

  useEffect(() => {
    (async () => {
      const res = await API.graphql(
        graphqlOperation(listJobTypes, { filter: { isActive: { eq: true } } })
      );

      const jobTypes = res?.data?.listJobTypes?.items;

      const id = user?.id ?? null;
      if (jobTypes.length === 0) return;
      if (id) {
        (async () => {
          const { data } = await API.graphql({
            query: getUser,
            variables: { id },
          });

          const applications = data?.getUser?.applications?.items;

          setJobRoles(
            jobTypes.map((jr) => ({
              ...jr,
              isApplied: !!applications.find(
                ({ jobTypeId }) => jobTypeId === jr.id
              ),
            }))
          );
        })();
      }
      setTimeout(() => {
        handleSubStepQueryParams();
      }, 750);
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Get user data for this section
  useEffect(() => {
    setUserSkills(user.skills || []);
    setUserLanguages(
      user.knownLanguages?.length > 0
        ? JSON.parse(JSON.stringify(user.knownLanguages))
        : [{ language: "English", level: "BASIC" }]
    );
    /* eslint-disable */ //disablisetJobRolesng to avoid potential infinite loops
  }, [user]);

  const getSuggestedSkills = () => {
    return jobRoles
      .filter(({ isApplied }) => isApplied === true)[0]
      ?.skills.flat()
      .filter(
        (value, index, self) =>
          index === self.findIndex((j) => j.name === value.name)
      )
      .splice(0, 10);
  };

  return (
    <div className="compact-text container-large">
      <h1 className="mb-[1vh]">Stacks and skills</h1>
      <Role
        jobRoles={jobRoles}
        setJobRoles={setJobRoles}
        className="pb-52 xl:pb-0"
        roleRef={refs[0]}
        userId={user.id}
        setDisabled={setDisabled}
        setAutoSaved={setAutoSaved}
        isCurrent={currentSubStep === 1}
      />
      {currentSubStep > 1 && (
        <Skills
          skills={userSkills}
          setSkills={setUserSkills}
          className={getCurrentClass(2, currentSubStep)}
          skillsRef={refs[1]}
          save={saveAttributes}
          setDisabled={setDisabled}
          isCurrent={currentSubStep === 2}
          setAutoSaved={setAutoSaved}
          suggestedSkills={getSuggestedSkills()}
          showTitle={true}
          user={user}
        />
      )}
      {currentSubStep > 2 && (
        <Languages
          user={user}
          userLanguages={userLanguages}
          setUserLanguages={setUserLanguages}
          className={getCurrentClass(3, currentSubStep)}
          langRef={refs[2]}
          save={saveAttributes}
          setDisabled={setDisabled}
          isCurrent={currentSubStep === 3}
        />
      )}
      {currentSubStep > 3 && (
        <div className={getCurrentClass(4, currentSubStep)} ref={refs[3]}>
          <ConnectAccounts
            user={user}
            dispatchUserUpdated={dispatchUserUpdated}
          />
        </div>
      )}
    </div>
  );
};

export default StackAndSkills;
