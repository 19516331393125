import { useEffect, useState } from "react";
import { PERIODS } from "./periods";
import YellowBox from "../../shared/YellowBox";
import DefaultInput from "components/FormInputs/DefaultInput";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

const schema = Yup.object().shape({
  otherNoticePeriod: Yup.number()
    .transform((value, originalValue) => {
      return originalValue === "" ? undefined : value;
    })
    .nullable()
    .typeError("Please enter a valid number")
    .min(0, "Notice period cannot be negative")
    .max(90, "Notice period cannot exceed 90 days")
    .integer("Notice period must be a whole number")
    .notRequired(),
});

const NoticePeriod = ({
  className,
  noticePeriodRef,
  user,
  save,
  setDisabled,
  isCurrent,
}) => {
  const [selectedNoticePeriod, setSelectedNoticePeriod] = useState(
    user?.noticePeriod
  );

  useEffect(() => {
    if (!isCurrent) return;
    setDisabled(selectedNoticePeriod === null);
  }, [selectedNoticePeriod, setDisabled, isCurrent]);

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      otherNoticePeriod: PERIODS.some(
        (period) => period.value === user?.noticePeriod
      )
        ? undefined
        : user?.noticePeriod || undefined,
    },
    mode: "onBlur",
  });

  const {
    setValue,
    watch,
    formState: { errors },
    trigger,
    getValues,
  } = methods;
  watch("otherNoticePeriod");

  const handleBlur = async () => {
    const currentValue = getValues("otherNoticePeriod");
    if (
      currentValue !== undefined &&
      currentValue !== "" &&
      currentValue !== user?.noticePeriod
    ) {
      await trigger("otherNoticePeriod");
      if (!errors.otherNoticePeriod) {
        const value = Math.floor(Math.abs(Number(currentValue)));
        save({ noticePeriod: value }, true);
        setSelectedNoticePeriod(value);
        setValue("otherNoticePeriod", value, { shouldValidate: true });
      }
    }
  };

  const handleYellowBoxClick = async (value) => {
    if (value !== selectedNoticePeriod) {
      setValue("otherNoticePeriod", "", { shouldValidate: true });
      setSelectedNoticePeriod(value);
      save({ noticePeriod: value }, true);
    }
  };

  return (
    <div className={className} ref={noticePeriodRef}>
      <div className="b1 mb-8">When is the soonest you could start work?</div>
      <div className="sm:flex gap-16">
        {PERIODS.map((period, index) => (
          <YellowBox
            key={index}
            handleClick={() => handleYellowBoxClick(period.value)}
            selected={user?.noticePeriod === period.value}
          >
            {period.label}
          </YellowBox>
        ))}
      </div>

      <FormProvider {...methods}>
        <form
          className="sm:w-2/3 w-4/5 flex justify-between mt-16"
          onBlur={handleBlur}
        >
          <Controller
            name="otherNoticePeriod"
            control={methods.control}
            render={({ field }) => (
              <DefaultInput
                {...field}
                type="number"
                placeholder="days"
                id="otherNoticePeriod"
                label="Other"
                min="0"
                step="1"
                onKeyDown={(e) => {
                  if (e.key === "," || e.key === ".") {
                    e.preventDefault();
                  }
                }}
              />
            )}
          />
        </form>
      </FormProvider>
    </div>
  );
};

export default NoticePeriod;
