import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";

import DefaultInput from "components/FormInputs/DefaultInput";
import Modal from "components/Modal";
import { ReactComponent as Close } from "images/new/1-button-close.svg";
import PrimaryBtn from "components/buttons/Primary";
import TextArea from "components/FormComponentsNew/Textarea";
import Select from "components/FormComponentsNew/Select";
import {
  JOB_TYPES,
  LOCATION_TYPES,
  MONTHS,
  getYearsList,
  placementIsActive,
} from "../utils";
import Skills from "../../StackAndSkills/Skills";
import SkillsChips from "components/SkillsChips";
import { SKILL_EXPERIENCE } from "utils/skills";
import { cloneDeep, isEqual } from "lodash";

const createCareerSchema = Yup.object().shape({
  companyName: Yup.string().required("Required"),
  description: Yup.string().nullable(),

  title: Yup.string().required("Required").nullable(),
  startDateMonth: Yup.string().required("Required"),
  startDateYear: Yup.string().required("Required"),
  endDateMonth: Yup.string().required("Required"),

  // full time, part time, self employed
  format: Yup.string().required("Required"),

  // on site, remote, hybrid
  locationType: Yup.string().required("Required"),

  stack: Yup.string().required("Required").nullable(),
});

const CreateEdit = ({
  isLoading,
  handleClose,
  onSubmit,
  experience,
  enableSkills,
}) => {
  const [yearLimit, setYearLimit] = useState();
  const [disableSkillSearch, setDisableSkillSearch] = useState(true);
  const [experienceOptions, setExperienceOptions] = useState(SKILL_EXPERIENCE);
  const [selectedSkill, setSelectedSkill] = useState();
  const [warningSkillsMessage, setWarningSkillsMessage] = useState([]);

  let experienceDefaultValues;
  if (experience) {
    experienceDefaultValues = {
      ...experience,
      startDateMonth: moment(experience?.startDate).format("MMMM"),
      startDateYear: moment(experience?.startDate).format("YYYY"),
      endDateMonth:
        experience?.endDate === null
          ? "Present"
          : moment(experience?.endDate).format("MMMM"),
      endDateYear: experience?.endDate
        ? moment(experience?.endDate).format("YYYY")
        : "",
    };

    if (experience?.isPlacement) {
      if (experience.careerStartDate) {
        experienceDefaultValues.startDateMonth = moment(
          experience?.careerStartDate
        ).format("MMMM");
        experienceDefaultValues.startDateYear = moment(
          experience?.careerStartDate
        ).format("YYYY");
      }

      // value for being PRESENT is set to empty string and not null to be able to handle careerEndDate as present and not the placement endDate
      // and to know the user have update the career/placement and rely on the placement 'careerEndDate' field
      if (experience.careerEndDate !== null) {
        if (experience.careerEndDate) {
          experienceDefaultValues.endDateMonth = moment(
            experience?.careerEndDate
          ).format("MMMM");
          experienceDefaultValues.endDateYear = moment(
            experience?.careerEndDate
          ).format("YYYY");
        } else {
          experienceDefaultValues.endDateMonth = "Present";
          experienceDefaultValues.endDateYear = "";
        }
      } else {
        const endDate = experience.actualEndDate ?? experience.endDate;
        if (!endDate || placementIsActive(endDate)) {
          experienceDefaultValues.endDateMonth = "Present";
          experienceDefaultValues.endDateYear = "";
        } else {
          experienceDefaultValues.endDateMonth = moment(endDate).format("MMMM");

          experienceDefaultValues.endDateYear = moment(endDate).format("YYYY");
        }
      }
    }
  }

  const methods = useForm({
    resolver: yupResolver(createCareerSchema),
    defaultValues: experience
      ? experienceDefaultValues
      : {
          endDateMonth: "Present",
        },
    mode: "all",
  });

  const { watch, setValue, getValues } = methods;

  const watchStartDateYear = watch("startDateYear");
  const watchStartDateMonth = watch("startDateMonth");
  const watchEndDateMonth = watch("endDateMonth");
  const watchEndDateYear = watch("endDateYear");

  const skills = watch("skills");

  // we are allowing the user to add skills. The experience is tied to the date range of the career
  // hence If the years between startDate and endDate change the experience of skills and the allowed options change
  useEffect(() => {
    if (!watchStartDateMonth || !watchStartDateYear) {
      setDisableSkillSearch(true);
      return;
    }

    if (watchEndDateYear || watchEndDateMonth === "Present") {
      const end =
        watchEndDateMonth === "Present"
          ? new Date()
          : moment(`${watchEndDateMonth}, ${watchEndDateYear}`, "MMM, YYYY");

      const start = moment(
        `${watchStartDateMonth}, ${watchStartDateYear}`,
        "MMM, YYYY"
      );

      let years = new Date(end).getFullYear() - new Date(start).getFullYear();
      const monthsDiff = new Date(end).getMonth() - new Date(start).getMonth();
      const daysDiff = new Date(end).getDate() - new Date(start).getDate();

      // Adjust the years if the end date is before the start date in terms of month/day
      if (monthsDiff < 0 || (monthsDiff === 0 && daysDiff < 0)) {
        years--;
      }

      const experienceOptionsCpy = { ...SKILL_EXPERIENCE };

      let newSkills = skills || [];

      const newWarningSkillsMessage = cloneDeep(warningSkillsMessage);

      setWarningSkillsMessage([]);

      if (years <= 2) {
        newSkills = newSkills?.map((sk) => {
          const skill = { ...sk };

          if (skill.experience === "high" || skill.experience === "medium") {
            if (
              !newWarningSkillsMessage.includes((e) => e.statsWight(skill.name))
            ) {
              newWarningSkillsMessage.push(
                `${skill.name}, from ${skill.experience} to low`
              );
            }

            skill.experience = "low";
          }
          return { ...skill };
        });

        delete experienceOptionsCpy.medium;
      }

      if (years < 5) {
        newSkills = newSkills?.map((sk) => {
          const skill = { ...sk };

          if (skill.experience === "high") {
            if (
              !newWarningSkillsMessage.includes((e) => e.statsWight(skill.name))
            ) {
              newWarningSkillsMessage.push(
                `${skill.name}, from high to medium`
              );
            }

            skill.experience = "medium";
          }

          return { ...skill };
        });

        delete experienceOptionsCpy.high;
      }

      if (years >= 0) {
        setDisableSkillSearch(false);
      } else {
        setDisableSkillSearch(true);
      }

      if (!isEqual(newSkills, skills)) {
        setValue("skills", [...newSkills]);
      }

      if (!isEqual(newWarningSkillsMessage, warningSkillsMessage)) {
        setWarningSkillsMessage(newWarningSkillsMessage);
      }

      setExperienceOptions(experienceOptionsCpy);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    watchStartDateYear,
    watchStartDateMonth,
    watchEndDateMonth,
    watchEndDateYear,
  ]);

  useEffect(() => {
    if (!watchStartDateYear || !watchStartDateMonth || !watchEndDateMonth)
      return;
    const startDateMonth = MONTHS.find(
      (m) => m.name === watchStartDateMonth
    )?.index;
    const endDateMonth = MONTHS.find(
      (m) => m.name === watchEndDateMonth
    )?.index;

    setYearLimit(
      new Date().getFullYear() -
        (startDateMonth > endDateMonth
          ? watchStartDateYear
          : watchStartDateYear - 1)
    );
  }, [watchStartDateYear, watchStartDateMonth, watchEndDateMonth]);

  const handleSubmittedSelectedSkill = (skills) => {
    const currentSkills = getValues().skills || [];

    const index = currentSkills.findIndex((sk) => sk.name === skills[0].name);

    if (index === -1) {
      setValue("skills", [...currentSkills, ...skills]);
    } else {
      currentSkills[index] = skills[0];
      setValue("skills", [...currentSkills]);
    }
    setSelectedSkill();
  };

  return (
    <Modal>
      <div className="bg-white p-8 rounded-sm pt-8 px-[104px]">
        <div className="relative">
          <h4 className="mb-6">
            {experience ? "Edit" : "Create"} work experience
          </h4>
          <FormProvider {...methods}>
            <form
              onSubmit={methods.handleSubmit(onSubmit, (err) =>
                console.error(err)
              )}
            >
              <div className="grid grid-cols-2 gap-7 w-[664px] mb-6">
                <DefaultInput id="title" label="Job Title" />
                <DefaultInput id="companyName" label="Company name" />

                <div className="grid grid-cols-2 gap-7">
                  <Select id="startDateMonth" label="Start month">
                    <option value="">Month</option>
                    {MONTHS.map(({ name, index }) => (
                      <option key={index} value={name}>
                        {name}
                      </option>
                    ))}
                  </Select>
                  <Select id="startDateYear" label="Start year">
                    <option value="">Year</option>
                    {getYearsList()}
                  </Select>
                </div>
                <div className="grid grid-cols-2 gap-7">
                  <Select
                    id="endDateMonth"
                    label={
                      watch("endDateMonth") === "Present"
                        ? "End date"
                        : "End month"
                    }
                    disabled={
                      !watch("startDateYear") || !watch("startDateMonth")
                    }
                  >
                    <option value="Present">Present</option>
                    {MONTHS.map(({ name, index }) => (
                      <option key={index} value={name}>
                        {name}
                      </option>
                    ))}
                  </Select>
                  {watch("endDateMonth") !== "Present" && (
                    <Select id="endDateYear" label="End year">
                      <option value="">Year</option>
                      {getYearsList(yearLimit)}
                    </Select>
                  )}
                </div>
                <Select id="format" label="Format">
                  <option value="">Please select</option>
                  {Object.entries(JOB_TYPES).map(([k, v]) => (
                    <option key={k} value={v}>
                      {k}
                    </option>
                  ))}
                </Select>
                <Select id="locationType" label="Location type">
                  <option value="">Please select</option>
                  {Object.entries(LOCATION_TYPES).map(([k, v]) => (
                    <option key={k} value={v}>
                      {k}
                    </option>
                  ))}
                </Select>
                <DefaultInput
                  fullWidth={true}
                  className="col-span-2"
                  id="stack"
                  label="Stacks, technologies, tools"
                />
                {enableSkills && (
                  <div className="col-span-2">
                    <Skills
                      containerClassName="!bg-transparent !w-full before:!bg-transparent h-14 border-2 placeholder-black text-black focus:border-brandTerciary-300 border-gray-600 appearance-none !rounded-xs focus:outline-none focus:shadow-outline bg-white"
                      skills={[]}
                      inputSkillSelected={selectedSkill}
                      modalTitle={
                        <label className="!text-xl">
                          Please indicate the duration the{" "}
                          <span className="font-bold">skill</span> was applied.
                        </label>
                      }
                      disableInputSearch={disableSkillSearch}
                      label={
                        <>
                          <span>Skills</span>{" "}
                          <span className="text-xs text-gray-600">
                            {"(Indicate specific applied Skills)"}
                          </span>
                        </>
                      }
                      experienceOptions={experienceOptions}
                      setSkills={handleSubmittedSelectedSkill}
                    />
                    {skills?.length > 0 && (
                      <div className="mt-2">
                        <SkillsChips
                          skills={skills}
                          title=""
                          className="px-2.5 !text-sm !mb-0"
                          wrapperClassName="!gap-0"
                          handleSkillClick={(sk) => {
                            setSelectedSkill();
                            setTimeout(() => {
                              setSelectedSkill(sk);
                            }, 0);
                          }}
                          handleSkillRemoveClick={(sk) => {
                            setValue(
                              "skills",
                              skills.filter(({ name }) => name !== sk.name)
                            );
                          }}
                        />
                      </div>
                    )}

                    {warningSkillsMessage?.length > 0 && (
                      <div className="text-yellow-500">
                        <p>Warning! </p>
                        <p>
                          Due to the new range of dates the following skill's
                          experience have change. Please verify new values you
                          can edit by clicking on the skill.
                        </p>
                        <p className="font-bold">
                          {warningSkillsMessage.join(", ")}
                        </p>
                      </div>
                    )}
                  </div>
                )}

                <TextArea
                  label="Intro paragraph"
                  id="description"
                  className="col-span-2"
                  subtext="Provide a summary to introduce yourself. This is your chance to sell yourself for future job opportunities and tell employers why you're the developer they've been looking for."
                />
              </div>
              <div className="flex justify-end gap-6">
                <PrimaryBtn
                  disabled={isLoading}
                  label={isLoading ? "Please wait..." : "Submit"}
                />
              </div>
            </form>
          </FormProvider>
          <Close
            className="absolute right-[-64px] top-0 cursor-pointer"
            onClick={handleClose}
          />
        </div>
      </div>
    </Modal>
  );
};

export default CreateEdit;
