import { useEffect } from "react";
import { API, graphqlOperation } from "aws-amplify";

import ImageWithCheck from "../../shared/ImageWithCheck";
import { createApplication } from "graphql/mutations";

const Role = ({
  jobRoles,
  setJobRoles,
  roleRef,
  setDisabled,
  isCurrent,
  setAutoSaved,
  className,
}) => {
  useEffect(() => {
    if (!isCurrent) return;
    setDisabled(!jobRoles?.some(({ isApplied }) => isApplied));
  }, [jobRoles, setDisabled, isCurrent]);

  const toggleIsActiveField = (jobTypeId) => {
    setJobRoles(
      jobRoles.map((jr) => {
        if (jobTypeId === jr.id) {
          jr.isApplied = !jr.isApplied;
        }
        return jr;
      })
    );
  };

  async function applyToJobRole(jobTypeId) {
    try {
      toggleIsActiveField(jobTypeId);
      await API.graphql(
        graphqlOperation(createApplication, { input: { jobTypeId } })
      );
    } catch (err) {
      toggleIsActiveField(jobTypeId);
      console.log("Error when submitting application to db");
      console.log(err);
    }
  }

  return (
    <div ref={roleRef} className={className}>
      <div className="flex items-baseline mb-[2vh] gap-6">
        <div className="b1">Select the roles that best describe you.</div>
      </div>
      <div className="grid justify-items-center gap-12 md:gap-y-4 md:gap-x-8 lg:gap-x-8 grid-cols-2 md:grid-cols-4 lg:grid-cols-4 xl:grid-cols-8">
        {jobRoles
          .sort((a, b) => a.sort - b.sort)
          .filter((j) => j.logo)
          .map((jobRole, index) => (
            <ImageWithCheck
              key={index}
              selected={jobRole.isApplied}
              logo={
                <img
                  src={require(`images/imagery/${jobRole.logo}`)}
                  alt="logo"
                />
              }
              handleClick={async () => {
                await applyToJobRole(jobRole.id);
                setAutoSaved(true);
              }}
              text={jobRole.displayName}
              className="w-[18vh] max-w-[144px]"
            />
          ))}
      </div>
    </div>
  );
};

export default Role;
