import { ReactComponent as FullTime } from "images/imagery/guy-headphones-blue.svg";
import { ReactComponent as PartTime } from "images/imagery/community-woman-man-fist-bump.svg";

export const PROJECT_STYLES = [
  {
    id: "FULLTIME",
    title: "Full-time jobs",
    logo: <FullTime />,
  },
  {
    id: "PARTTIME",
    title: "Part-time jobs",
    logo: <PartTime />,
  },
];
